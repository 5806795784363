import React from 'react'

function Header() {
    return (
        <>
            <section className="top-header container">
                <div style={{ textAlign: "center",padding: "5px", flex: "0.75" }}>
                    <img className="" src="images/skit_logo.png" alt="" />
                    <img className="actetLogo" src="images/logo.jpg" alt="" />
                </div>
                <div className='site-header' style={{ fontFamily: "oswald", textAlign: "center" }}>

                    <div className="logo-top-line3" style={{ color: "rgb(0, 83, 136)" }}>International Conference<br /><br />on<br /></div>
                    <div className="div1" style={{ color: "rgb(0, 83, 136)" }}>Advanced Computing Techniques in Engineering & Technology</div>
                    <div className="logo-top-line">ACTET-2025</div>
                    <div className="div2">Organized By <br />Department of Electrical Engineering</div>
                    <div className="div2" style={{ color: "#0763ab" }}>Swami Keshvanand Institute of Technology, Management & Gramothan(SKIT), Jaipur, Rajasthan, India</div>
                    <div className="logo-top-line2" style={{ color: "#c0392b" }}><b>24-25 January, 2025</b></div>
                    {/* <div><a className="link-marquee" href="https://link.springer.com/book/10.1007/978-3-031-54162-9" target="_blank">To download conference proceedings please click here...</a></div> */}
                    {/* <marquee className="marquee"style={{ width: "80%", direction: "left",color:"#e74c3c",fontSize:"larger" }}><b>The after-conference proceeding of the ACTET 2023 will be published in SCOPUS Indexed Springer Book Series, ‘Communications in Computer and Information Science’.(The Conference will be held in online mode only)</b></marquee> */}
                </div>
                <div className="siteLogo" style={{ textAlign: "center", fontFamily: "oswald" , lineHeight: "5em", padding: "5px", flex: "0.75" }}>
                    <img className='' src='images/scrs.jpg' alt='' />
                    <img className="" style={{ width: '125px', height: '70px' }} src="images/springer2.jpg" alt="" />
                </div>
            </section>
        </>
    )
}

export default Header;